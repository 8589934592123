<template>
<!-- 中间 -->
    <div style="position:relative" class="people">
		<div class="container">
			<div class="contentItem lightBack" v-for="(item,index) in list" :key="index">
				<img class="img" :src="item.img" >
				<div>
					<div class="lightGrayFont" style="font-size:0.12rem;">{{item.title}}</div>
					<countTo :startVal='item.preNum' :endVal='item.num' :duration='3000' class="whiteFont" style="font-size:0.22rem;"></countTo>
					<!-- <div class="whiteFont" style="font-size:0.22rem;" >{{item.num}}</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {timestamp} from "/src/assets/js/websocket";

import countTo from 'vue-count-to';
	export default {
		components: { countTo },
		data() {
		    return { 
		        list:[
					{ 
						title:'注册人数' , 
						num:0, 
						img:require('../../../assets/images/running.png'),
						preNum:0 
					},
					{ 
						title:'在线运动人数' , 
						num:0, 
						img:require('../../../assets/images/num.png'),
						preNum:0 
					},
					{ 
						title:'今日运动人数' , 
						num:0, 
						img:require('../../../assets/images/average.png'),
						preNum:0 
					},
					{ 
						title:'运动人数' , 
						num:0, 
						img:require('../../../assets/images/people.png') ,
						preNum:0 
					}, 
				],
				infoTimer:""
		    }
		},
		methods: {
			getData(){
				let that = this;

				var data = {
					// token: window.getToken(),
					// app_id: window.appId,
					// time: timestamp(),
				}

				let url;
     			let info = JSON.parse(window.localStorage.getItem("screenUserInfo"));
				if(info.is_city == 1) url = window.appHost+"/api/v1/screen/Venue/vital"
				else url = window.appHost+"/api/v1/screen/Venue/vital?area="+info.name
				let result = window.ajaxRequest(url,JSON.stringify(data),"post")
				// console.log(result)
				that.list[0].preNum = that.list[0].num;
				that.list[1].preNum = that.list[1].num;
				that.list[2].preNum = that.list[2].num;
				that.list[3].preNum = that.list[3].num;
				that.list[0].num = result.msg.venueRegNum;
				that.list[1].num = result.msg.online_total;
				that.list[2].num = result.msg.day_sportsNum;
				that.list[3].num = result.msg.all_sportsNum;
			}	
		},
		mounted () {
			let that = this;
			that.getData();
			that.infoTimer = setInterval(() => {
				that.getData();
			}, 3000);
		},
		beforeDestroy () {
			clearInterval(this.infoTimer)
		}
	}
</script>

<style scoped>
	.container{
		width: 7.82rem;
		height: 1.19rem;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.contentItem{
		width: 1.76rem;
		height: 0.81rem;
		display: flex;
		/* justify-content: center; */
        /* justify-content: space-around; */
        padding:0rem 0.1rem;
        box-sizing: border-box;
		align-items: center;
	}
	.img{
		width: 0.33rem;
		height: 0.34rem;
		margin-right: 0.1rem;
	}
</style>